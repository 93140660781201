<template>
  <div class="d-inline">
    <v-card class="mx-auto">
      <v-card-title>
        Need a document scanner?
        <v-spacer></v-spacer>
        <v-icon class="cursor-pointer" @click="show = !show" color="red"
          ><slot name="scanIcon">mdi-scan-helper</slot></v-icon
        >
        <v-spacer></v-spacer>
        <v-btn icon @click="show = !show"
          ><v-icon>{{
            show ? 'mdi-chevron-up' : 'mdi-chevron-down'
          }}</v-icon></v-btn
        >
      </v-card-title>
      <v-slide-y-transition>
        <v-card-text v-if="show">
          <p>
            Use your smartphone to scan any document, like a {{ doc }}, save it
            on your computer or email it to yourself, and then you can store it
            in your secure Peeps File archive.
          </p>
          <div class="d-flex justify-center my-3">
            <div>
              <a href="https://swiftscan.app" target="_blank">
                <v-img
                  class="cursor-pointer"
                  max-width="50"
                  src="@/assets/img/SwiftScan.png"
                ></v-img>
              </a>
            </div>
            <div style="max-width: 200px; margin-left: 1rem">
              Click this scanner icon to learn more about this scanning app
            </div>
          </div>
          <div class="d-flex">
            <div>
              <v-img src="@/assets/img/PeepsBeside.svg" max-width="35"></v-img>
            </div>
            <div>
              <p class="peeps--font ml-1">
                Note: there are other scanning apps and alternatives on the
                market, but my technical team has had good success with this
                app, and thought sharing that with you might be helpful.
              </p>
            </div>
          </div>
        </v-card-text>
      </v-slide-y-transition>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    doc: {
      type: String,
      default: ''
    },
    docNumber: {
      type: String,
      default: ''
    },
    showDetail: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    this.show = this.showDetail
  },
  data: () => ({
    show: true
  }),
  watch: {
    showDetail() {
      this.show = this.showDetail
    }
  }
}
</script>

<style></style>
